const Storage = cartItems => {
    if (typeof window !== "undefined") {
        window.localStorage.setItem("cart", JSON.stringify(cartItems.length > 0 ? cartItems : []));
    }
};

export const sumItems = cartItems => {
    Storage(cartItems);
    const itemsCount = cartItems.reduce((total, product) => total + product.quantity, 0);

    const sendableItemsCount = cartItems.reduce(
        (total, product) =>
            total + (product.can_be_sent ? product.items || 1 : 0) * product.quantity,
        0,
    );

    const subtotal = cartItems
        .reduce((total, product) => total + product.price * product.quantity, 0)
        .toFixed(2);

    /*const shipping_total = cartItems
        .reduce(
            (total, product) =>
                total + (product.shipment || 0) * product.quantity,
            0
        )
        .toFixed(2);*/

    return {
        itemsCount,
        // subtotal,
        total: parseFloat(subtotal).toFixed(2),
        // shipping_total,
        sendableItemsCount,
    };
};

export const CartReducer = (state, action) => {
    switch (action.type) {
        case "CLEAR":
            return {
                checkout: false,
                cartItems: [],
                ...sumItems([]),
            };

        case "CHECKOUT":
            return {
                checkout: !state.checkout,
                cartItems: [...state.cartItems],
                ...sumItems(state.cartItems),
            };

        case "ADD_ITEM":
            if (!state.checkout) {
                if (!state.cartItems.find(item => item.id === action.payload.id)) {
                    // Si no existe el ítem
                    state.cartItems.push({
                        ...action.payload,
                        quantity: 1,
                    });
                } else {
                    // Si existe el ítem incrementamos en una unidad
                    state.cartItems[
                        state.cartItems.findIndex(item => item.id === action.payload.id)
                    ].quantity++;
                }

                return {
                    ...state,
                    ...sumItems(state.cartItems),
                    cartItems: [...state.cartItems],
                };
            } else {
                return state;
            }

        case "DELETE_ITEM":
            if (!state.checkout) {
                console.log("delete item", action.payload.id);

                return {
                    ...state,
                    ...sumItems(state.cartItems.filter(item => item.id !== action.payload.id)),
                    cartItems: [...state.cartItems.filter(item => item.id !== action.payload.id)],
                };
            } else {
                return state;
            }

        case "REMOVE_ITEM":
            if (!state.checkout) {
                if (state.cartItems.find(item => item.id === action.payload.id)) {
                    // Si existe el item
                    if (
                        state.cartItems[
                            state.cartItems.findIndex(item => item.id === action.payload.id)
                        ].quantity === 1
                    ) {
                        // Si la cantidad es 1 ya eliminamos el ítem
                        return {
                            ...state,
                            ...sumItems(
                                state.cartItems.filter(item => item.id !== action.payload.id),
                            ),
                            cartItems: [
                                ...state.cartItems.filter(item => item.id !== action.payload.id),
                            ],
                        };
                    } else {
                        state.cartItems[
                            state.cartItems.findIndex(item => item.id === action.payload.id)
                        ].quantity--;

                        return {
                            ...state,
                            ...sumItems(state.cartItems),
                            cartItems: [...state.cartItems],
                        };
                    }
                } else {
                    return state;
                }
            } else {
                return state;
            }

        default:
            return state;
    }
};
