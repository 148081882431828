import React, { createContext, useReducer } from "react";
import { CartReducer, sumItems } from "./CartReducer";

const CartContextProvider = ({ children }) => {
    const storage =
        typeof window !== "undefined" && window.localStorage.getItem("cart")
            ? JSON.parse(typeof window !== "undefined" && window.localStorage.getItem("cart"))
            : [];

    const [state, dispatch] = useReducer(CartReducer, {
        cartItems: storage,
        locked: false,
        ...sumItems(storage),
    });

    const addProduct = payload => {
        dispatch({ type: "ADD_ITEM", payload });
    };

    const removeProduct = payload => {
        dispatch({ type: "REMOVE_ITEM", payload });
    };

    const deleteProduct = payload => {
        dispatch({ type: "DELETE_ITEM", payload });
    };

    const clearCart = () => {
        dispatch({ type: "CLEAR" });
    };

    const handleCheckout = () => {
        dispatch({ type: "CHECKOUT" });
    };

    return (
        <CartContext.Provider
            value={{
                addProduct,
                removeProduct,
                clearCart,
                handleCheckout,
                deleteProduct,
                // cartCounter,
                ...state,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

const initialState = {
    addProduct: () => {},
    removeProduct: () => {},
    clearCart: () => {},
    deleteProduct: () => {},
};

export const CartContext = createContext(initialState);
export default CartContextProvider;
